const initialState = {
  preReq: null,
  checkList: [],
  setUp: "",
  savedTrades: null,
  message: "",
  count: null,
  userAttrs: null,
  strategies: [],
  gapSearches: [],
  savedFees: null,
  stats: null,
  paywall: "paywall-up"
};
export default initialState;